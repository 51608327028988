import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { updateConsent, ConsentPreferences } from "../utils/gtm";
import "../styles/CookiesBar.css";

const CookiesBar = () => {
  const [cookies, setCookie] = useCookies(['cookieConsent', 'cookiePreferences']);
  const [showBanner, setShowBanner] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [countdown, setCountdown] = useState(10);

  // Set default preferences
  const defaultPreferences: ConsentPreferences = {
    analytics: true,
    ads: true,
    functionality: true,
    personalization: true
  };

  const [preferences, setPreferences] = useState<ConsentPreferences>(defaultPreferences);

  useEffect(() => {
    // If user hasn't made a choice yet (no cookieConsent cookie)
    if (!cookies.cookieConsent) {
      // Show the banner
      setShowBanner(true);
      // Set default preferences immediately
      setCookie('cookieConsent', true, cookieOptions);
      setCookie('cookiePreferences', defaultPreferences, {
        ...cookieOptions,
        maxAge: 365 * 24 * 60 * 60
      });
      updateConsent(defaultPreferences);

      // Start countdown
      const countdownTimer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownTimer);
            setShowBanner(false);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      return () => {
        clearInterval(countdownTimer);
      };
    } else {
      // User has made a choice before, use their saved preferences
      setPreferences(cookies.cookiePreferences || defaultPreferences);
      updateConsent(cookies.cookiePreferences || defaultPreferences);
    }
  }, []);

  const cookieOptions = {
    path: "/",
    maxAge: 365 * 24 * 60 * 60, // Always 1 year now
    secure: window.location.protocol === 'https:',
    sameSite: 'lax' as const
  };

  const handleAcceptAll = () => {
    const allPreferences: ConsentPreferences = {
      analytics: true,
      ads: true,
      functionality: true,
      personalization: true
    };
    
    setCookie('cookieConsent', true, cookieOptions);
    setCookie('cookiePreferences', allPreferences, {
      ...cookieOptions,
      maxAge: 365 * 24 * 60 * 60
    });
    updateConsent(allPreferences);
    setShowBanner(false);
  };

  const handleSavePreferences = () => {
    setCookie('cookieConsent', true, cookieOptions);
    setCookie('cookiePreferences', preferences, cookieOptions);
    updateConsent(preferences);
    setShowBanner(false);
  };

  const handleRejectAll = () => {
    const noPreferences: ConsentPreferences = {
      analytics: false,
      ads: false,
      functionality: false,
      personalization: false
    };
    
    setCookie('cookieConsent', true, cookieOptions);
    setCookie('cookiePreferences', noPreferences, cookieOptions);
    updateConsent(noPreferences);
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="cookies-bar">
          <div className="cookies-content">
            <p>
              Mes naudojame slapukus, kad pagerintume jūsų naršymo patirtį ir pateiktume pritaikytą turinį. 
              Jūs galite pasirinkti, kuriuos slapukus norite leisti. Tęsdami naršymą, slapukai jau yra įjungti,
              bet juos galite bet kada išjungti.
              {countdown > 0 && <span> (Pranešimas išnyks po {countdown}s)</span>}
            </p>
            
            {showDetails && (
              <div className="cookies-preferences">
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.analytics}
                    onChange={(e) => setPreferences({...preferences, analytics: e.target.checked})}
                  />
                  Analitika
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.ads}
                    onChange={(e) => setPreferences({...preferences, ads: e.target.checked})}
                  />
                  Reklama
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.functionality}
                    onChange={(e) => setPreferences({...preferences, functionality: e.target.checked})}
                  />
                  Funkcionalumas
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={preferences.personalization}
                    onChange={(e) => setPreferences({...preferences, personalization: e.target.checked})}
                  />
                  Personalizavimas
                </label>
              </div>
            )}
            
            <div className="cookies-buttons">
              <button onClick={() => setShowDetails(!showDetails)} className="secondary">
                {showDetails ? 'Slėpti nustatymus' : 'Rodyti nustatymus'}
              </button>
              <button onClick={handleRejectAll} className="secondary">
                Atmesti visus
              </button>
              {showDetails ? (
                <button onClick={handleSavePreferences} className="primary">
                  Išsaugoti pasirinkimus
                </button>
              ) : (
                <button onClick={handleAcceptAll} className="primary">
                  Priimti visus
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesBar;