declare global {
    interface Window {
      dataLayer: any[];
      gtag: (...args: any[]) => void;
    }
  }
  
  export interface ConsentPreferences {
    analytics?: boolean;
    ads?: boolean;
    functionality?: boolean;
    personalization?: boolean;
  }
  
  export const initializeGTM = () => {
    window.dataLayer = window.dataLayer || [];
  };
  
  export const updateConsent = (preferences: ConsentPreferences) => {
    // Update consent state
    window.gtag('consent', 'update', {
      'analytics_storage': preferences.analytics ? 'granted' : 'denied',
      'ad_storage': preferences.ads ? 'granted' : 'denied',
      'ad_user_data': preferences.ads ? 'granted' : 'denied',
      'ad_personalization': preferences.ads ? 'granted' : 'denied',
      'functionality_storage': preferences.functionality ? 'granted' : 'denied',
      'personalization_storage': preferences.personalization ? 'granted' : 'denied',
      'security_storage': 'granted'
    });

    // If analytics is granted, initialize GA4 properly
    if (preferences.analytics) {
      window.gtag('config', 'G-MNCR6WR376', {
        send_page_view: true,
        page_path: window.location.pathname + window.location.search
      });
    }

    // Push to dataLayer for GTM
    window.dataLayer.push({
      'event': 'update_consent',
      'consent_state': {
        'analytics_storage': preferences.analytics ? 'granted' : 'denied',
        'ad_storage': preferences.ads ? 'granted' : 'denied',
        'ad_user_data': preferences.ads ? 'granted' : 'denied',
        'ad_personalization': preferences.ads ? 'granted' : 'denied',
        'functionality_storage': preferences.functionality ? 'granted' : 'denied',
        'personalization_storage': preferences.personalization ? 'granted' : 'denied',
        'security_storage': 'granted'
      }
    });
  };
  
  export const pushToDataLayer = (data: any) => {
    // Ensure GA4 events are properly formatted
    if (data.event === 'page_view') {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_title: document.title,
        page_path: data.page_path,
        send_to: 'G-MNCR6WR376'
      });
    }
    
    window.dataLayer.push(data);
  };